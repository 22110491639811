import Axios from 'axios'
import Moment from 'moment'
import VueMeta from 'vue-meta'

import Vue from 'vue';

import router from './router';
import store from './store';
import App from './App.vue';

import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.prototype.axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

Vue.prototype.moment = Moment
Vue.prototype.moment.locale("de-ch")

Vue.use(VueMeta, {keyName: "head"});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
