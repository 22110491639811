<template>
  <div>
    <main-picture image="home_2.jpg"/>
    <div class="end">
      <v-container fluid>
        <h1>Willkommen auf dem Reit und Erlebnishof Hinder</h1>
        <p class="center">Im Hinterthurgau liegt unser kleiner und überschaubarer Hof.
          Ein idealer Ort, wo sich Tiere und Menschen in Ruhe begegnen können. Momentan bieten wir
          erlebnisreiche Events für Kinder an. Je nach Thema verbringen wir Zeit mit unseren Tieren,
          im Garten oder auf dem Pferderücken.
        </p>
      </v-container>
    </div>
    <v-container>
      <v-row class="simpleImg end">
        <simple-img image="heart.png" title="Verantwortung"><p slot="content">Tiere bedeuten
          Verantwortung. Im Umgang mit ihnen lernen Kinder respektvoll und achtsam mit dem ihm
          anvertrauten Lebewesen umzugehen. Dafür erfährt es bedingungslose Akzeptanz.</p>
        </simple-img>
        <simple-img image="hand.png" title="Sinne"><p slot="content">Das Riechen von frischem Heu,
          das Spüren von sanftem Fell, das Hören von Pferdegetrappel oder der Geschmack von frisch
          gepresstem Apfelsaft. Unser Erlebnishof bietet etwas für alle Sinne. </p>
        </simple-img>
        <simple-img image="body.png" title="Bewegung"><p slot="content">Unser Hof lädt zum Bewegen
          ein. Sowohl auf dem Pferderücken als auch beim Füttern und Pflegen der Tiere braucht es
          Körpereinsatz. So werden die motorischen Fähigkeiten verbessert und die Kinder erleben
          ihren eigenen Körper.
        </p></simple-img>
      </v-row>
    </v-container>
  </div>
</template>

<script>

  import MainPicture from "../components/MainPicture";
  import SimpleImg from "../components/SimpleImg";

  export default {
    components: {MainPicture, SimpleImg}
  };

</script>

<style lang="scss">

  .simpleImg {
    justify-content: space-around;
  }

</style>
