<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>

  import GoogleMapsApiLoader from 'google-maps-api-loader'

  export default {
    data() {
      return {
        mapConfig: {
          "zoom": 12,
          "center": {"lat": 47.495433, "lng": 9.057405},
          "backgroundColor": {RGB: "255,255,255"},

        },
        google: null,
        map: null
      }
    },
    async mounted() {
      this.google = await GoogleMapsApiLoader({
        apiKey: "AIzaSyD-ITGnZ7srBMWYVO99K3kr-U_bAdfygzY"
      })
      this.initializeMap();
    },
    methods: {
      initializeMap() {
        const mapContainer = this.$refs.googleMap;
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
        new this.google.maps.Marker({
          "position": {"lat": 47.495433, "lng": 9.057405},
          "map": this.map
        })
      }
    }
  }
</script>

<style scoped>
  .google-map {

    width: 100%;
    height: 400px;
  }
</style>
