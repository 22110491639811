import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue'
import Angebot from "../views/Angebot";
import Kontakt from "../views/Kontakt";
import UnserHof from "../views/UnserHof"
import Anmeldung from "../views/Anmeldung";

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/angebot',
    name: 'angebot',
    component: Angebot
  },
  {
    path: '/unserhof',
    name: 'unserhof',
    component: UnserHof
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt
  },
  {
    path: '/anmeldung',
    name: 'Anmeldung',
    component: Anmeldung
  }

]


const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: (to.hash)}
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
});


export default router;
