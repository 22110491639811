<template>
  <div>
    <main-picture image="unserhof.jpg"/>

    <div class="end">
      <v-container fluid>
        <h1>Unser Hof</h1>
        <p class="center">Inmitten von grünen Wiesen, Wald und Obstbäumen liegt der kleine Weiler
          Hittingen. Bei uns gibt es viel zu entdecken –Tiere, Pflanzen, Geräte, Maschinen und jede
          Menge Platz um sich zu verweilen. </p>
      </v-container>
    </div>

    <div id="uebermich" class="scroll"></div>
    <div class="end box-color">
      <v-container fluid>
        <h2>Über mich</h2>
        <v-row class="parent">
          <v-col cols="12" md="8" sm="8">
            <p>
              Meine ganze Kindheit habe ich auf diesem Hof verbracht und bin mit den Tieren und der
              Natur aufgewachsen. Seit 2012 gebe ich Reitstunden und habe als reiterliche
              Qualifikation das Brevet und später den Silbertest CC absolviert. Während
              dieser Zeit konnte ich sehr wertvolle Erfahrungen sammeln. Ich durfte viele
              unterschiedliche Kinder kennen lernen, ihnen das Reiten beibringen oder das Leben auf
              einem Hof zeigen. Mit meiner Maturaarbeit (2018) wollte ich dieses Thema vertiefen und
              beschäftigte mich mit der positiven Wirkung von Pferden auf Menschen, zum einen auf
              einer sozial/psychischen Ebene, wie auch auf einer rein physischen Ebene. Nach meiner
              Matura arbeitete ich während einem Semester an einer Primarschule. Auf diese Weise
              lernte ich erneut viel über den Umgang mit Kindern. Momentan bin ich Studentin und
              freue mich nebst dem Studium mit Kindern arbeiten zu können und ihnen die Faszination
              an Tier und Natur weiterzugeben.
            </p>
            <p>
              Um für die nötige Sicherheit zu sorgen, besonders im Umgang mit Pferden, wird zusammen
              mit mir immer eine zusätzliche Person anwesend sein.</p>
          </v-col>
          <v-col cols="12" md="4" sm="4" class="profile">
            <v-img src="../assets/ich.jpg" aspect-ratio="1"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div id="tiere" class="scroll"></div>
    <div class="end">
      <v-container fluid>
        <h2>Tiere</h2>
        <p class="column">Bei uns sind Pferde, Alpakas, Schafe, Hühner, Katzen, Kaninchen und
          Bienen Zuhause. In unserem Freilaufstall leben sechs Freibergerpferde und ein
          Shetlandpony.
          Die Pferde erhalten viel Auslauf und haben ein ruhiges Temperament. Sie bleiben auch mit
          viel Trubel um sie herum gelassen und sind mit Anfängern geduldig. Unser Pony zieht gerne
          die Kutsche, oder lässt die ganz Kleinen auf sich reiten. Von unseren zwei Alpakas nutzen
          wir die Wolle für die Herstellung von Duvets. Auf den Wiesen rund um den Hof weiden die
          Schafe. Jedes Jahr dürfen wir uns über Lämmli freuen – ein Highlight für alle. Alpakas und
          Schafe freuen sich über Besuch, denn oft gibt es für sie noch einen Leckerbissen. Die
          Hühner liefern uns Eier, dafür dürfen sie ab und zu über den ganzen Hof spazieren. Dank
          unseren Bienen sind wir das ganze Jahr mit Honig versorgt. Es ist spannend, beim
          Entstehungsprozess dabei zu sein und schlussendlich ein Hof eigenes Produkt zu geniessen.
        </p>
      </v-container>
    </div>
    <div class="end">
      <v-container fluid>
        <gallery
          :images="['t1.jpg', 't2.jpg', 't3.jpg', 't4.jpg', 't5.jpg', 't6.jpg', 't7.jpg','t8.jpg', 't9.jpg','i1.jpg', 'i2.jpg','i3.jpg']"/>
      </v-container>
    </div>
    <div id="infrastruktur" class="scroll"></div>
    <div class="end">
      <v-container fluid>
        <h2>Infrastruktur</h2>
        <p>Unser Stall besteht aus zwei überdachten Ställen, einem Reitplatz sowie
          einer Sattelkammer. Bei schlechtem Wetter können wir auch in unseren „Theorieraum“ gehen
          und dort basteln, neue Dinge lernen oder im Winter mit heissem Tee aufwärmen. Zusätzlich
          sind auch sanitäre Anlagen vorhanden.
        </p>
      </v-container>
    </div>
  </div>
</template>

<script>
  import MainPicture from "../components/MainPicture";
  import Gallery from "../components/Gallery";

  export default {
    components: {MainPicture, Gallery},
    head: {
      title: 'Unser Hof'
    }
  };
</script>

<style lang="scss">

  @media (min-width: 1264px) {
    .column {
      column-count: 2;
      column-gap: 48px;
    }
  }

</style>
