<template>
  <v-menu v-if="item.children" v-model="open" close-on-click close-on-content-click offset-y
          open-on-hover class="nav-menu" :attach="true">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text active-class="top-nav-active" color="black"
             v-bind="$attrs" class="nav-item">{{item.title}}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(child, index) in item.children" :key="index"
                   :to="item.link + child.link" class="submenu">
        <v-list-item-title>{{ child.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-btn v-else text active-class="top-nav-active" color="black"
         v-bind="$attrs" class="nav-item">{{item.title}}
  </v-btn>
</template>

<script>

  export default {
    props: ["item"],
    data: () => ({
      open: false
    })
  }

</script>

<style lang="scss">

  .nav-menu div {
    border-radius: 0;
  }

  .nav-item {
    align-self: center;
    border-radius: 0 !important;
  }

  .top-nav-active {
    border-bottom: 2px solid #b77713;
    border-radius: 0 !important;

    &:before {
      opacity: 0 !important;
    }
  }

</style>s
