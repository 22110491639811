<template>
  <div :class="['module', dark ? 'module-dark': 'module-light' ]">
    <v-container>
      <slot></slot>
    </v-container>
  </div>
</template>

<script>

  export default {
    props: ["dark"]
  }

</script>

<style lang="scss">

  .module {
    padding: 4vh 24px;
    /*font-size: 1.4rem;*/
    /*text-align: justify;*/
    /*text-align-last: auto;*/

    &.module-dark {
      background-color: #efebe6;
    }

    &.module-light {
      background-color: white;
    }
  }

</style>
