<template>
  <v-col cols="12" md="3" class="colbox">
    <div class="imgbox">
      <v-img :src="src" contain></v-img>
    </div>
    <h3>{{title}}</h3>
    <p>
      <slot name="content"></slot>
    </p>
  </v-col>
</template>

<script>
  export default {
    name: "simpleImg",
    props: ["image", "title"],
    computed: {
      src: function () {
        return require("../assets/" + this.image);
      }
    }
  }
</script>

<style lang="scss">

  .colbox {
    text-align: center !important;

    h3 {
      padding-bottom: 18px !important;
    }
  }

  .imgbox {
    height: 100px;
    margin: 0 auto 35px auto;
    display: flex;
  }

</style>
