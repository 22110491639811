<template>
  <div>
    <v-container fluid>
      <div :id="event.title.replace(/ /g, '-')" class="scroll"></div>
      <div class="end">
        <v-row class="parent">
          <v-col cols="12" md="4">
            <v-img :src="src" height="400px" class="event-img"></v-img>
          </v-col>
          <v-col cols="12" md="8" class="event-content">
            <h2>{{event.title}}</h2>
            <p>{{ event.description}}</p><br>
            <table>
              <tr>
                <td>Altersempfehlung</td>
                <td>{{event.age}}</td>
              </tr>
              <tr>
                <td>Teilnehmerzahl</td>
                <td>max. {{event.participants}} Kinder</td>
              </tr>
              <tr v-if="event.duration && !dates.length">
                <td>Dauer</td>
                <td>etwa {{event.duration.replace(/\.0+$/,'')}} Stunden</td>
              </tr>
              <tr>
                <td>Kosten</td>
                <td>Erstes Kind CHF {{event.price}}</td>
              </tr>
              <tr>
                <td></td>
                <td>Jedes weitere CHF 40</td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <h3 v-if="dates.length">Nächste Daten</h3>
        <v-row v-if="dates.length">
          <date v-for="date in dates" :date="date" :key="date.id"
                :full="date.registrations >= event.participants"></date>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>

  import Date from "../components/Date";

  export default {
    props: ["event"],
    components: {Date},
    data() {
      return {
        dates: []
      }
    },
    async mounted() {
      this.dates = (await this.axios.get('events/' + this.event.id + '/dates')).data
      for (const date of this.dates)
        this.$set(date, "registrations", (await this.axios.get("registration/count", {params: {date: date.id}})).data)
      this.scroll()
    },
    methods: {
      scroll() {
        // Fix Scroll to Anchor after populating News
        if (this.$route.hash)
          this.$nextTick().then(() => window.scrollTo(0, document.getElementById(this.$route.hash.replace('#', '')).offsetTop));
      }
    },
    computed: {
      src: function () {
        return encodeURI(process.env.VUE_APP_API_URL + "/static/events/" + this.event.id + "/" + this.event.image);
      }
    }
  }

</script>

<style lang="scss">

  .parent {
    margin-bottom: 30px;
  }

  .event-content {
    padding-left: 20px;

    table {
      font-size: 20px;

      td {

        &:first-of-type {
          font-weight: bold;
        }

        &:last-of-type {
          padding-left: 12px;
        }
      }
    }
  }

  .event-img {
    max-width: 400px;
  }

</style>
