<template>
  <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 495.23 86.74"
       xml:space="preserve">
    <title>logo2</title>
    <path class="cls-1"
          d="M69.68,259.72H61.86L54.26,245H47.37v10.32h4.55v4.39h-14v-4.39h4.38V228H37.94v-4.39H53.76q6.7,0,9.82,2.86a9.82,9.82,0,0,1,3.11,7.58,10,10,0,0,1-7.5,9.93l6,11.32h4.49ZM61.3,234.19c0-2.18-.63-3.76-1.91-4.72S56,228,53,228H47.37V240.9H53c3,0,5.17-.59,6.41-1.77A6.51,6.51,0,0,0,61.3,234.19Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M94.32,252.39a8.41,8.41,0,0,1-3.16,5.55,11.4,11.4,0,0,1-7.55,2.33,12,12,0,0,1-8.71-3.22q-3.33-3.21-3.33-9.32a14.55,14.55,0,0,1,3.11-9.55,10.55,10.55,0,0,1,8.6-3.77c3.4,0,6,1.05,7.93,3.16a11.32,11.32,0,0,1,2.89,7.66,16.35,16.35,0,0,1-.33,3.17H76.68q.33,7.87,7.26,7.88a6.15,6.15,0,0,0,4.36-1.39,6.86,6.86,0,0,0,2-3.33Zm-5.27-7.27a7.37,7.37,0,0,0-1.19-4.69,5.12,5.12,0,0,0-4.69-2.25,5.37,5.37,0,0,0-4.58,2,9.84,9.84,0,0,0-1.86,4.91Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M110.8,259.72H97.87v-3.78h4.06v-17.2H97.71v-3.83h9.1v21h4Zm-9.21-30.64v-5.71h5v5.71Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M132.89,252.72q-1,7.56-8.32,7.55a8.19,8.19,0,0,1-6-2.08,7.74,7.74,0,0,1-2.13-5.8V238.63h-3.83v-3.72h3.83v-7.82h4.82v7.82h9.49v3.72h-9.49v13q0,4.44,3.83,4.44a4.36,4.36,0,0,0,2.44-.81c.82-.53,1.39-1.78,1.72-3.74Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M175.62,259.72h-9v-4.44a8.73,8.73,0,0,1-8.38,5,9,9,0,0,1-6.14-2.22q-2.52-2.22-2.52-7V238.74h-4v-3.83h8.88v14.54c0,2.52.49,4.29,1.47,5.33a5.23,5.23,0,0,0,4,1.55,6,6,0,0,0,4.83-2.19,7.68,7.68,0,0,0,1.89-4.86V238.74h-4.83v-3.83h9.71v21h4.16Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M208,259.72H195.16v-3.78h4V245.23c0-2.51-.48-4.3-1.44-5.35a4.91,4.91,0,0,0-3.83-1.58,6.28,6.28,0,0,0-4.94,2,7.41,7.41,0,0,0-1.94,4.77v10.82h4v3.78H178.12v-3.78h4v-17.2H178v-3.83H187v4.22q2.61-4.73,8.43-4.72a8.87,8.87,0,0,1,6.11,2.22q2.49,2.22,2.49,6.94v12.37h4Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M238.61,259.72h-8.83v-4.89a11.62,11.62,0,0,1-3.24,3.83,8.81,8.81,0,0,1-5.52,1.61,9.75,9.75,0,0,1-7.72-3.3c-1.92-2.2-2.88-5.23-2.88-9.07a17.15,17.15,0,0,1,2.63-9.3q2.64-4.18,8.52-4.19,5.72,0,8.21,4.44V225.36h-5.71v-3.82h10.54v34.4h4Zm-8.83-11.44v-3a6.91,6.91,0,0,0-2.1-5,6.84,6.84,0,0,0-4.83-1.88,6.23,6.23,0,0,0-5.47,2.66,11.17,11.17,0,0,0-1.86,6.55q0,4.22,2,6.44a6.38,6.38,0,0,0,4.94,2.22,6.29,6.29,0,0,0,5.19-2.61A9.31,9.31,0,0,0,229.78,248.28Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M280.78,259.72H253.65v-4.39H258V228h-4.38v-4.39h26.69L281.45,234l-4.38.38L276,228H263.08v11.26h16.43v4.33H263.08v11.71h13.37l1.11-6.32,4.33.38Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M300.37,234.52a7.39,7.39,0,0,1,4.44,1.33l1,6.94-3.83.44L301,239a5.05,5.05,0,0,0-1.94-.39,3.93,3.93,0,0,0-3.17,1.58,6.41,6.41,0,0,0-1.27,4.14v11.65H300v3.78h-14.2v-3.78h4v-17.2h-4.16v-3.83h8.71v4.55a8.41,8.41,0,0,1,2.25-3.52A5.36,5.36,0,0,1,300.37,234.52Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1" d="M321.35,307.72H308.48v-3.78h4V225.36h-4.16v-3.82h9v82.4h4Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M347,252.39a8.41,8.41,0,0,1-3.16,5.55,11.4,11.4,0,0,1-7.55,2.33,12,12,0,0,1-8.71-3.22q-3.33-3.21-3.33-9.32a14.55,14.55,0,0,1,3.11-9.55,10.55,10.55,0,0,1,8.6-3.77,10.11,10.11,0,0,1,7.94,3.16,11.36,11.36,0,0,1,2.88,7.66,16.35,16.35,0,0,1-.33,3.17H329.4q.33,7.87,7.27,7.88a6.14,6.14,0,0,0,4.35-1.39,6.86,6.86,0,0,0,2-3.33Zm-5.27-7.27a7.37,7.37,0,0,0-1.19-4.69,5.11,5.11,0,0,0-4.69-2.25,5.37,5.37,0,0,0-4.58,2,9.84,9.84,0,0,0-1.86,4.91Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M376.84,246.73a17.12,17.12,0,0,1-2.66,9.3q-2.67,4.25-8.49,4.24-6,0-8.49-4.55c0,.3-.12,1-.25,2s-.21,1.7-.25,2h-4.88c.18-1.15.37-2.35.55-3.61a24.91,24.91,0,0,0,.28-3.72v-27h-4v-3.82h8.88V239.9a11.67,11.67,0,0,1,3.14-3.85,8.64,8.64,0,0,1,5.57-1.64,9.93,9.93,0,0,1,7.61,3.22Q376.85,240.86,376.84,246.73Zm-5.1.39q0-4.27-2-6.49a6.29,6.29,0,0,0-4.91-2.22,6.38,6.38,0,0,0-5.33,2.49,8.71,8.71,0,0,0-2,5.22v2.78a7.39,7.39,0,0,0,2,5.24,6.62,6.62,0,0,0,5.05,2.14,6,6,0,0,0,5.38-2.67A11.38,11.38,0,0,0,371.74,247.12Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M410.25,259.72H397.38v-3.78h4V245.23c0-2.51-.48-4.3-1.44-5.35a4.91,4.91,0,0,0-3.83-1.58,6.28,6.28,0,0,0-4.94,2,7.41,7.41,0,0,0-1.94,4.77v10.82h4v3.78H380.34v-3.78h4v-17.2h-4.17v-3.83h9.05v4.22q2.61-4.73,8.43-4.72a8.87,8.87,0,0,1,6.11,2.22c1.66,1.48,2.5,3.79,2.5,6.94v12.37h4Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M425.57,259.72H412.64v-3.78h4.05v-17.2h-4.22v-3.83h9.1v21h4Zm-9.21-30.64v-5.71h5.05v5.71Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M449.38,252.45a6.55,6.55,0,0,1-3.08,5.77,13.8,13.8,0,0,1-7.8,2,18.25,18.25,0,0,1-9.71-2.44l.77-6,3.83.38-.11,3.22a8.69,8.69,0,0,0,2.42.78,15,15,0,0,0,2.58.22,10,10,0,0,0,4.3-.89,2.91,2.91,0,0,0,1.91-2.77,2.64,2.64,0,0,0-1.55-2.58,14.51,14.51,0,0,0-3.86-1.14c-1.53-.26-3.06-.58-4.58-1a8.79,8.79,0,0,1-3.83-2,5.54,5.54,0,0,1-1.55-4.3c0-2.77,1-4.7,3.08-5.77a14,14,0,0,1,6.58-1.61,17.34,17.34,0,0,1,4.8.64,19.33,19.33,0,0,1,4.07,1.64l.56,6-3.88.44-.56-3.88a11.29,11.29,0,0,0-4.77-1.06,6.64,6.64,0,0,0-3.39.78,2.72,2.72,0,0,0-1.33,2.55,2.51,2.51,0,0,0,1.53,2.45,14,14,0,0,0,3.77,1.08,42.53,42.53,0,0,1,4.5,1,8.8,8.8,0,0,1,3.77,2.05A5.77,5.77,0,0,1,449.38,252.45Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M482.17,259.72H469.24v-3.78h4.05V245.23c0-2.51-.5-4.3-1.5-5.35a5.14,5.14,0,0,0-3.94-1.58,6.19,6.19,0,0,0-4.82,2,7.25,7.25,0,0,0-1.95,4.63v11h4.05v3.78H452.26v-3.78h4V225.36h-4v-3.82h8.82v17.64a9.11,9.11,0,0,1,8.49-4.77,8.87,8.87,0,0,1,6.11,2.22c1.66,1.48,2.5,3.79,2.5,6.94v12.37h4Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M509.59,247.34a12.77,12.77,0,0,1-3.53,9.32,12.39,12.39,0,0,1-9.29,3.61,11.73,11.73,0,0,1-9-3.5,12.87,12.87,0,0,1-3.3-9.21,14.3,14.3,0,0,1,3.17-9.32q3.15-3.88,9.43-3.89t9.38,3.86A14.13,14.13,0,0,1,509.59,247.34Zm-5.11-.17a9.71,9.71,0,0,0-2-6.49A6.82,6.82,0,0,0,497,238.3a6.5,6.5,0,0,0-5.52,2.52,10.38,10.38,0,0,0-1.92,6.47,10.85,10.85,0,0,0,1.86,6.41,6.33,6.33,0,0,0,5.52,2.63,6.6,6.6,0,0,0,5.58-2.58A10.55,10.55,0,0,0,504.48,247.17Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-1"
          d="M525.9,221a13.68,13.68,0,0,1,3.3.39,7.6,7.6,0,0,1,3,1.5l1,6-3.88.5-1.06-4.17a8.43,8.43,0,0,0-2.61-.38c-2.62,0-3.94,1.7-3.94,5.1v4.94h6.39v3.72h-6.39v17.31h5.22v3.78H512.8v-3.78h4.06V238.63h-3.45v-3.72h3.45v-4.38a9.55,9.55,0,0,1,2.55-6.89A8.61,8.61,0,0,1,525.9,221Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-2"
          d="M300.8,307.54H289.16v-2.61h4.36V291.8H273.88v13.13h4.35v2.61H266.59v-2.61H271v-28.1h-4.36v-2.62h11.64v2.62h-4.35v12.46h19.64V276.83h-4.36v-2.62H300.8v2.62h-4.31v28.1h4.31Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-2"
          d="M361.79,276.83h-4.31v30.71h-2L335.74,280h-.2v25H341v2.61H328.77v-2.61h4v-28.1h-4.46v-2.62h6.66l19.54,27.28h.2V276.83h-5.07v-2.62h12.2Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-2"
          d="M397.69,290.88a16.63,16.63,0,0,1-4.49,12q-4.49,4.69-12.38,4.69H365.89V305h4.36V276.77h-4.36v-2.56h14.82q8.06,0,12.52,4.74A16.76,16.76,0,0,1,397.69,290.88Zm-3.13,0A14.62,14.62,0,0,0,391,280.72q-3.57-3.95-10.34-4h-7.48V305h7.48q6.78,0,10.34-3.9A14.56,14.56,0,0,0,394.56,290.88Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-2"
          d="M426,307.54H401.48v-2.61h4.36v-28.1h-4.36v-2.62h24l1,9.33-2.62.31-1-7H408.76v12.46h16.16v2.61H408.76v13h14.67l1-7,2.57.26Z"
          transform="translate(-37.94 -220.98)"/>
    <path class="cls-2"
          d="M460.25,307.54h-6.16l-7.33-14.31h-7.85v11.7h4.72v2.61H431.58v-2.61h4.36v-28.1h-4.36v-2.62h14.21c4.13,0,7,.92,8.71,2.74a9.34,9.34,0,0,1,2.52,6.54,9.81,9.81,0,0,1-1.82,5.77,9,9,0,0,1-5.57,3.46L456,304.93h4.26Zm-6.31-24a6.27,6.27,0,0,0-2-5.1q-2-1.67-6.41-1.66h-6.62v13.94h6.77q4.41,0,6.34-1.92A7.1,7.1,0,0,0,453.94,283.59Z"
          transform="translate(-37.94 -220.98)"/>
  </svg>

</template>

<script>
  export default {
    name: "Logo.vue"
  }
</script>

<style scoped>
  .cls-1 {
    fill: #b77713;
  }

  .cls-2 {
    fill: #010101;
  }
</style>
