<template>
  <v-col cols="12" sm="4" lg="3">
    <v-card :to="{path: '/anmeldung', query: {id: date.id}}" class="date pa-4" color="#af8c53" dark
            :disabled="full">
      <div class="font-weight-bold">{{moment.utc(date.from).format('dddd LL')}}</div>
      <div>{{moment.utc(date.from).format('LT')}} bis {{moment.utc(date.to).format('LT')}} Uhr</div>
      <div class="text-right mt-6">
        <div v-if="!full">
          <v-icon size="large" color="white">mdi-arrow-right</v-icon>
          Zur Anmeldung
        </div>
        <div v-else>Leider Ausgebucht</div>
      </div>
    </v-card>
  </v-col>
</template>

<script>

  export default {
    props: ["date", "full"]
  }

</script>

<style lang="scss">

  .date {
    font-size: 18px;

    i {
      top: -1px;
      margin-right: 4px;
    }

    &:hover i {
      margin-right: 0;
    }
  }

</style>
