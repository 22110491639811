<template>
  <div class="nav">
    <v-list-item :to="navItem.link">
      <v-list-item-content>
        <v-list-item-title>{{navItem.title}}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon v-if="navItem.children" @click.prevent="open = !open">
        <v-icon>mdi-chevron-{{open ? "up" : "down"}}</v-icon>
      </v-list-item-icon>
    </v-list-item>

    <v-list-group v-if="navItem.children" v-model="open" class="nav-group">
      <v-list-item v-for="(child, index) in navItem.children" :key="index"
                   :to="navItem.link + child.link">
        <v-list-item-title>{{child.title}}</v-list-item-title>
      </v-list-item>
    </v-list-group>

  </div>
</template>

<script>
  export default {
    props: ["navItem"],
    name: "sideBar.vue",

    data() {
      return {
        open: false
      }
    }
  }
</script>

<style lang="scss">
  .nav-group {
    > :first-child {
      display: none;
    }

    > :last-child {
      padding-left: 20px;
    }
  }

  .nav a {
    &:before {
      border-radius: 0 !important;
    }

    border-radius: 0 !important;
  }

</style>
