<template>
  <v-app>

    <v-navigation-drawer v-model="drawer" app right class="d-flex d-sm-none" disable-resize-watcher>
      <v-list nav>
        <side-bar-item v-for="item in navItems" :key="item.title"
                       :navItem="item"></side-bar-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="white" dense app fixed class="app-bar">
      <v-container>
        <router-link to="/" style="height: 65%; align-self: center">
          <logo class="logo"></logo>
        </router-link>
        <v-spacer></v-spacer>
        <div class="d-none d-sm-flex">
          <top-nav-button v-for="item in navItems" :key="item.title"
                          :item="item" :to="item.link"></top-nav-button>
        </div>
        <v-app-bar-nav-icon class="d-flex d-sm-none" dense="true" @click="drawer = !drawer"
                            color="black">
        </v-app-bar-nav-icon>

      </v-container>
    </v-app-bar>

    <v-main class="content">
      <router-view></router-view>
    </v-main>

    <v-footer app absolute class="footer">
      <v-container>
        <v-row>
          <v-col class="text-left" cols="4">Reit- und Erlebnishof Hinder</v-col>
          <v-col class="text-center" cols="4">© {{ new Date().getFullYear() }}</v-col>
          <v-col class="text-right" cols="4">
            <a target="_blank" :href="src">Wichtige Hinweise</a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>

</template>

<script>

  import SideBarItem from "./components/SideBarItem.vue";
  import TopNavButton from "./components/TopNavButton";
  import Logo from "./components/Logo.vue";

  export default {
    components: {SideBarItem, TopNavButton, Logo},
    head: {
      title: 'Home',
      titleTemplate: '%s | Hof Hinder'
    },
    data() {
      return {
        drawer: false,
        right: null,
        events: []
      }
    },
    mounted() {
      this.axios
        .get('events')
        .then(response => {
          this.events = response.data;
        }) //(this.events = response.data)
    },
    computed: {
      src: function () {
        return process.env.VUE_APP_API_URL + "/static/files/wichtige_hinweise.pdf";
      },
      navItems: function () {
        return [
          {title: 'Home', link: "/home"},
          {
            title: 'Angebot',
            link: "/angebot",
            children: this.events.length ? this.events.map(event => {
              return {title: event.title, link: "#" + event.title.replace(/ /g, '-')}
            }) : []
          },
          {
            title: 'Unser Hof', link: "/unserhof", children: [
              {title: "Über mich", link: "#uebermich"},
              {title: "Tiere", link: "#tiere"},
              {title: "Infrastruktur", link: "#infrastruktur"}
            ]
          },
          {title: 'Kontakt', link: "/kontakt"}
        ]
      }
    }
  };

</script>

<style lang="scss">

  * {
    padding: 0;
    margin: 0;
    font-family: 'Zilla Slab', serif;
  }

  .box-color {
    background-color: #efebe6;
  }

  @media (min-width: 960px) {
    .container {
      max-width: 900px !important;
    }
  }

  @media (min-width: 1264px) {
    .container {
      max-width: 1185px !important;
    }
  }

  .container {
    width: 100%;
    /*margin-right: auto !important;*/
    /*margin-left: auto !important;*/
  }

  .app-bar {

    > :first-child {
      padding-left: 0;
      padding-right: 0;

      .container {
        height: 100%;
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .logo {
      height: 100%;
    }
  }

  .footer {
    background-color: lighten(#222, 10%) !important;

    a, div {
      color: white !important;
    }
  }

  h1 {
    font-size: 38px !important;
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 25px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p {
    font-size: 20px !important;
  }

  .end {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .center {
    text-align: center;
  }

  .scroll {
    position: absolute;
    margin-top: -48px;
  }

</style>
