<template>
  <div>
    <main-picture image="contact.jpg"/>
    <div class="end center">
      <v-container fluid>
        <h1>Kontakt</h1>
        <p>Bei Fragen, Anregungen oder Wünschen kontaktieren Sie uns bitte. Haben Sie einen
          spezifischen Wunsch, besteht je nach dem auch die Möglichkeit ein individuelles Angebot
          zusammenzustellen. Wir versuchen Ihnen weiterzuhelfen und freuen uns über jede Nachricht.
          Möchten Sie Ihr Kind anmelden, finden Sie unter Angebot verschiedene Daten und die
          jeweilige Anmeldung.
        </p>
      </v-container>
    </div>
    <div class="end">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" class="address">
            <p>
              Reit und Erlebnishof Hinder<br>
              Sereina Hinder<br>
              Hittingen 384 <br>
              9502 Braunau
            </p>
            <p>Allgemeine Anfragen</p>
            <p>
              <a href="mailto:info@hof-hinder.ch">info@hof-hinder.ch</a>
            </p>
            <p>Camping (Landcamp)</p>
            <p>
              <a href="mailto:camping@hof-hinder.ch">camping@hof-hinder.ch</a>
            </p>

            <br>
            <p>
              Sie finden uns etwas ausserhalb von Braunau. Mit dem Bus von Wil (Nr. 706, Richtung
              Braunau) dauert die Fahrt zu Haltestelle "Braunau, Hittingen" 10 min. Von dort aus
              sind es 5 Gehminuten zu unserem Hof.
            </p>
          </v-col>
          <v-col cols="12" md="8">
            <GoogleMapsLoader/>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>

  import GoogleMapsLoader from '../components/GoogleMapLoader'
  import MainPicture from "../components/MainPicture";

  export default {
    components: {MainPicture, GoogleMapsLoader},
    head: {
      title: 'Kontakt'
    }
  }

</script>

<style lang="scss">

  .address {
    padding-right: 50px;

    a {
      color: black !important;
    }
  }

</style>
