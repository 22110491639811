<template>

  <!--  <v-parallax height="400">-->
  <v-img :src="src" height="80vh"></v-img>
  <!--  </v-parallax>-->
</template>

<script>
  export default {
    props: ["image"],
    data() {
      return {}
    },
    computed: {
      src: function () {
        return require("../assets/" + this.image);
      }
    }
  }
</script>

<style lang="scss">


</style>
