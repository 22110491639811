<template>
  <module>
    <h1>Anmeldung</h1>
    <transition name="fade" mode="out-in">
      <div v-if="status === 1" :key="1">
        <div v-if="moment.utc(date.from).isBefore(moment(), 'day')"
             class="d-flex justify-center py-12">
          <p>Eine Anmeldung ist leider nicht mehr möglich</p>
        </div>
        <div v-else-if="!this.children.length && full" class="d-flex justify-center py-12">
          <p>Leider sind schon alle Plätze belegt</p>
        </div>
        <v-form ref="form" v-else-if="!registered" :disabled="loading">
          <v-row>
            <v-col cols="12" sm="6" class="pr-sm-6 pr-md-10">
              <h3>{{event.title || "Datum"}}</h3>
              <div class="mb-8" v-if="moment.utc(date.from).isSame(date.to,'day')">
                {{moment.utc(date.from).format('dddd LL')}}
                von {{moment.utc(date.from).format('LT')}} bis
                {{moment.utc(date.to).format('LT')}} Uhr
              </div>
              <div class="mb-8" v-else>
                {{moment.utc(date.from).format('dddd D. MMMM')}} bis
                {{moment.utc(date.to).format('dddd LL')}}
              </div>
              <h3>Kontaktperson</h3>
              <v-text-field v-model="parent.firstname" label="Vorname" :rules="[rules.required]"
                            required/>
              <v-text-field v-model="parent.lastname" label="Nachname" :rules="[rules.required]"
                            required/>
              <v-text-field v-model="street" label="Strasse" :rules="[rules.required]" required/>
              <v-row>
                <v-col cols="3">
                  <v-text-field v-model="zip" label="PLZ" :rules="[rules.required, rules.plz]"
                                validate-on-blur required/>
                </v-col>
                <v-col cols="9">
                  <v-text-field v-model="city" label="Ort" :rules="[rules.required]" required/>
                </v-col>
              </v-row>
              <v-text-field v-model="email" label="E-Mail" validate-on-blur
                            :rules="[rules.required, rules.email]" required/>
              <v-text-field v-model="phone" label="Telefonnummer"
                            :rules="[rules.required, rules.phone]" required/>
            </v-col>
            <v-col cols="12" sm="6" class="pl-sm-6 pl-md-10">
              <h3 v-if="!children.length">Kind</h3>
              <h3 v-else>Kinder</h3>
              <div class="children mt-3" v-show="children.length">
                <div v-for="(child, index) in children" :key="index" class="py-1 px-3">
                  <div>{{child.firstname}} {{child.lastname}},
                    {{moment(child.birthdate).format('LL')}}
                  </div>
                  <v-btn icon @click="deleteChild(index)" small>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-form ref="child" v-show="!full" :disabled="loading">
                <v-text-field v-model="child.firstname" label="Vorname"
                              :rules="[rules.required]" required/>
                <v-text-field v-model="child.lastname" label="Nachname"
                              :rules="[rules.required]" required/>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                        transition="scale-transition" min-width="200px" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="formattedBirthdate" label="Geburtsdatum"
                                  readonly v-on="on" required
                                  :rules="[menu || rules.required]"/>
                  </template>
                  <v-date-picker ref="picker" v-model="child.birthdate" no-title locale="de-CH"
                                 :max="moment().subtract(2, 'years').format('YYYY-MM-DD')"
                                 min="1950-01-01" first-day-of-week="1" @change="save"/>
                </v-menu>
                <v-btn class="mt-4" @click="addChild" outlined width="100%" :disabled="loading">
                  Kind hinzufügen
                </v-btn>
              </v-form>
              <v-checkbox v-model="checkbox" :rules="[rules.required]" required class="mt-12">
                <div slot="label">
                  Ich habe die <a target="_blank" @click.stop :href="src">wichtigen Hinweise</a>
                  gelesen und akzeptiert.
                  Diese Einverständniserklärung gilt auch für alle weiteren, nicht über dieses
                  Formular registrierten, Angebote von Reit- und Erlebnishof Hinder
                </div>
              </v-checkbox>
              <v-btn @click="submit" color="#b7770e" class="mt-6" dark depressed :loading="loading">
                Anmelden
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <div v-else>
          <p class="center">
            Sie wurden erfolgreich angemeldet. Ich werde mich sobald wie möglich mit Ihnen in
            Verbindung setzen und Ihre Anmeldung bestätigen. Ich freue mich auf einen
            erlebnisreichen Tag auf unserem Hof!<br>
            <router-link to="/home">Zurück zur Homeseite</router-link>
          </p>
        </div>
      </div>
      <div v-else class="d-flex justify-center py-12" :key="2">
        <p v-if="status===2">
          Beim Laden der Anmeldung ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.
        </p>
        <v-progress-circular v-else indeterminate size="80" color="primary"/>
      </div>
    </transition>
  </module>
</template>

<script>

  import Module from "../components/Module";

  export default {
    head: {
      title: "Anmeldung"
    },
    components: {Module},
    data: () => ({
      children: [],
      child: {firstname: "", lastname: "", birthdate: null},
      parent: {firstname: "", lastname: ""},
      street: "",
      zip: "",
      city: "",
      email: "",
      phone: "",
      menu: false,
      date: {},
      checkbox: null,
      registered: false,
      registrations: 0,
      loading: false,
      status: 0,
      rules: {
        required: value => !!value || "Erforderlich",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@" ]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Ungültige Email"
        },
        plz: value => {
          const pattern = /^[0-9]{4}$/gm
          return pattern.test(value) || "Ungültige PLZ"
        },
        phone: value => {
          const pattern = /^[0-9, +]*$/gm
          return pattern.test(value) || "Ungültige Nummer"
        }
      },
    }),
    watch: {
      menu(val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"))
      },
    },
    methods: {
      submit() {
        const parentValid = this.$refs.form.validate();
        const childValid = (this.children.length && !this.filled) || this.$refs.child.validate()
        if (!parentValid || !childValid) return
        this.loading = true
        this.axios.post("registration", {
          parentFirstname: this.parent.firstname,
          parentLastname: this.parent.lastname,
          street: this.street,
          zip: this.zip,
          city: this.city,
          email: this.email,
          phone: this.phone,
          children: [...this.children, ...(!this.children.length || this.filled ? [this.child] : [])],
          checkbox: this.checkbox,
          date: this.date.id
        }).then(() => {
          this.registered = true
        }).catch(e => {
          console.log(e)
        }).finally(() => {
          this.loading = false
        })
      },
      save(date) {
        this.$refs.menu.save(date)
      },
      addChild() {
        if (!this.$refs.child.validate()) return
        this.children.push(Object.assign({}, this.child))
        this.child.birthdate = null // Doesn't get cleared by reset
        this.$refs.child.reset()
      },
      deleteChild(index) {
        this.children.splice(index, 1)
      }
    },
    computed: {
      src: function () {
        return process.env.VUE_APP_API_URL + "/static/files/wichtige_hinweise.pdf";
      },
      formattedBirthdate: function () {
        return this.child.birthdate ? this.moment(this.child.birthdate).format("LL") : ""
      },
      filled: function () {
        return !!(this.child.firstname || this.child.lastname || this.child.birthdate)
      },
      full: function () {
        if (!this.date.event) return false
        return this.registrations + this.children.length >= this.event.participants + 2
      },
      event: function () {
        return this.date.event || {}
      }
    },
    async mounted() {
      try {
        this.registrations = (await this.axios.get("registration/count", {params: {date: this.$route.query.id}})).data
        this.date = (await this.axios.get("events/dates/" + this.$route.query.id)).data
        this.status = 1
      } catch (e) {
        this.status = 2
        console.log(e)
      }
    }
  }

</script>

<style lang="scss">

  .back {
    margin-right: 10px;
    margin-left: auto;
    display: flex;

    p {
      font-size: 18px !important;
      margin-left: 5px;
    }
  }

  .children > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #efebe6;
    margin-bottom: 10px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

</style>
