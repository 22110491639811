<template>
  <div>
    <main-picture image="angebot.jpg"/>
    <div class="end">
      <v-container fluid>
        <h1>Unser Angebot</h1>
        <p class="center">Auf unserem Hof können Kinder mit Tieren in Kontakt treten. Uns ist
          wichtig, dass sich beide, Tier und Mensch gleichermassen wohlfühlen. Deshalb sorgen wir
          für eine ruhige Atmosphäre ohne Druck. Wir legen Wert darauf, dass Kinder lernen wie man
          Tiere artgerecht hält, was ihr natürliches Verhalten ist und welche Bedürfnisse sie
          haben. </p>
        <!--        <v-row>-->
        <!--          <v-col cols="12" md="7">-->
        <p class="center">
          Bei unseren Erlebnisnachmittagen sind immer mindestens zwei Personen anwesend. Die
          Angebote können je nach Wetter oder Jahreszeit etwas voneinander abweichen. Es sind
          alle
          Kinder herzlich willkommen, auch solche ohne Reiterfahrung. Die Angebote werden je nach
          Interesse und Jahreszeit angepasst.</p>
        <!--          </v-col>-->
        <!--          <v-spacer></v-spacer>-->
        <!--          <v-col cols="12" md="4">-->
        <!--          </v-col>-->
        <!--          <v-spacer></v-spacer>-->
        <!--        </v-row>-->
        <v-card tile flat outlined min-height="50%" class="card">
          <h3 class="card-title">Kindergeburtstag?</h3>
          <v-card-text class="card-text">
            Gerne können Sie unsere Angebote auch für einen persönlichen Anlass buchen.
            Ebenfalls
            sind wir offen für spezielle Wünsche und Anregungen oder ein individuell
            zusammengestelltes Programm. Persönliche Anlässe werden ab zwei Kindern
            durchgeführt.
            Wir freuen uns von Ihnen zu hören.
            <a href="mailto:info@hof-hinder.ch">info@hof-hinder.ch</a>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <event class="event-background" v-for="event in events" :event="event" :key="event.id"/>
  </div>
</template>

<script>

  import MainPicture from "../components/MainPicture";
  import Event from "../components/Event";

  export default {
    components: {MainPicture, Event},
    head: {
      title: 'Angebot'
    },
    data() {
      return {
        info: null,
        events: [],
      }
    },
    mounted() {
      this.axios
        .get('events')
        .then(response => (this.events = response.data))
    }
  }

</script>

<style lang="scss">

  .event-background:nth-of-type(odd) {
    background-color: #efebe6;
  }

  .card {
    margin-top: 40px;
    border-color: #b77713 !important;
    border-width: 2px !important;
  }

  .card-text {
    font-size: 20px !important;
    color: black !important;
    line-height: 1.5;
    text-align: center;
  }

  .card-title {
    text-align: center;
    padding-top: 20px;
  }

</style>
